// .language-bar {
//     background-color: #28292a;
//     color: white;
//     font-size: 14px;
//     width: 100%;
//     box-shadow: 0px 3px 7px 2px $header-subnav-shadow-color;

//     @media screen and (max-width: 1023px) {
//         position: fixed;
//         left: 0px;
//         top: 0px;
//         right: 0px;
//         z-index: 21;
//     }

//     .content-wrapper {
//         height: 28px;
//         display: flex;
//         align-items: center;
//         justify-content: flex-end;
//         position: relative;

//         & > label {
//             font-size: 14px;
//             font-weight: lighter;
//             color: #bebebe;

//             &:after {
//                 content: ':';
//             }
//         }

//         & > button {
//             position: relative;
//             font-size: 14px;
//             line-height: 1;
//             background-color: transparent;
//             border: none;
//             width: auto;
//             height: auto;
//             padding: 3px 18px 3px 3px;
//             margin: 2px 0px 0px 5px;

//             span {
//                 &:after {
//                     content: '';
//                     display: block;
//                     position: absolute;
//                     right: 3px;
//                     top: 8px;
//                     width: 0px;
//                     height: 0px;
//                     border-left: 5px solid transparent;
//                     border-right: 5px solid transparent;
//                     border-top: 5px solid #bebebe;
//                 }
//             }
//         }

//         & > ul.language-menu {
//             position: absolute;
//             top: 28px;
//             right: 0px;
//             margin: 0px;
//             list-style: none;
//             background-color: white;
//             padding: 5px 0px;
//             z-index: 37;
//             box-shadow: 0px 3px 7px 2px $header-subnav-shadow-color;

//             & > li {
//                 &:hover {
//                     background-color: #efefef;
//                 }

//                 & > a {
//                     display: block;
//                     text-decoration: none;
//                     color: black;
//                     font-size: 14px;
//                     padding: 5px 36px 5px 12px;
//                 }
//             }
//         }
//     }
// }

// .admin-user {
//     .language-bar {
//         margin-top: 50px;
//     }
// }

.language-bar {
  background: grey;
  z-index: 99999999999;
  position: fixed;
  color: white;
  label {
    color: white;
  }
}
