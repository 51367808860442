.content {
  &.emp-verify {
    // Max width larger than other pages because it was requested that specific text fit on a single line.
    max-width: 550px;

    .content-header {
      font-size: 1.3125rem;
      color: #cb117d;

      @media screen and (min-width: 550px) {
        font-size: 2rem;
      }
    }

    .content-description {
      margin: 0 0 1.8rem 0;
    }

    .content-label {
      margin-left: 8px;
    }

    .content-body {
      background: none;
    }

    .form-group {
      &.submit {
        text-align: center;

        @media screen and (max-width: 425px) {
          text-align: center;
          .primary,
          .secondary {
            width: 100%;
          }
        }
      }
    }
  }
}
