//*************************** Header ********************//

// Defaults - Shared by all viewports or set defaults
.header {
  width: 100%;
  height: $header-height;
  background-color: $header-background-color;
  position: fixed;
  top: 0px;
  z-index: 22;

  .content-wrapper {
    margin: 0 auto;
    height: 100%;
    width: 90%;
    display: flex;
    justify-content: space-between;
    max-width: $content-wrapper-max-width;
  }

  .logoWrapper {
    display: flex;
    align-items: center;

    .logo-primary {
      padding: 0px;

      img,
      svg {
        height: 100%;
        width: 119px;
        vertical-align: middle;
      }
    }
  }

  .main-nav {
    display: none;
  }

  .navMenuWrapper {
    padding: 10px 0px;
    display: flex;

    .points-display {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0px;
      order: 1;

      .balance::before {
        display: inline-block;
        content: '';
        background-image: url('../../../assets/img/nav_points.svg');
        background-size: 1rem;
        height: 1rem;
        width: 1rem;
        margin-right: -0.5rem;
        @media screen and (max-width: 1023px) {
          vertical-align: top;
        }
      }

      .balance {
        & > a {
          font-size: 10px;
          margin: 0px 0px 0px 15px;

          span.val {
            font-size: 16px;

            &:after {
              // space after number
              content: ' ';
              width: 2px;
              display: inline-block;
            }
            & + br {
              display: none; // keeps text from going to next line
            }
          }
        }
      }
    }
  }

  .avatar {
    width: 32px;
    height: 32px;

    .initials {
      background-color: rgb(110, 110, 110) !important;

      span {
        color: white;
      }
    }
  }

  .site.admin-user & {
    top: 50px;
    padding-top: 0px;
  }

  .site.language & {
    top: $language-bar-height;
    padding-top: 0px;
  }

  .site.admin-user.language & {
    top: ($admin-bar-height + $language-bar-height);
    padding-top: 0px;
  }
}

// Mobile View
@media screen and (max-width: 767px) {
  .header {
    position: fixed;
    top: 0px;
    height: $header-height-mobile;
    // z-index: 1;
  }
  .logoWrapper {
    .logo-primary,
    .logo-secondary {
      img,
      svg {
        height: 38px;
        width: 119px;
      }
    }
    .logo-secondary {
      padding-left: 1rem;
    }
  }
}

// Tablet View
@media screen and (max-width: 1023px) {
  .logoWrapper {
    .logo-primary,
    .logo-secondary {
      img,
      svg {
        height: 38px;
        width: 119px;
      }
    }
    .logo-secondary {
      padding-left: 1rem;
    }
  }

  .navMenuWrapper {
    .points-display {
      .balance > a {
        font-size: 11px;
      }
      .avatar {
        width: 41px;
        height: 41px;
      }
    }
  }
}

// Desktop View
@media screen and (min-width: 1024px) {
  .header {
    .main-nav {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .logoWrapper {
      .logo-primary,
      .logo-secondary {
        img,
        svg {
          height: 52px;
          width: 166px;
        }
      }
      .logo-secondary {
        padding-left: 1rem;
      }
    }

    .navMenuWrapper {
      .points-display {
        border-left: 1px solid #ccc;
        .balance > a {
          font-size: 13px;
          span.val {
            font-size: 21px;
          }
        }
        .avatar {
          width: 56px;
          height: 56px;
        }
      }
    }
  }
}

@import '../../../app/q360-comps/header-nav/src/header-nav.scss';

$avatar-height: 55px;
$avatar-width: 55px;
$avatar-color: $header-font-color;
$avatar-background-color: black;
$avatar-border-radius: 27.5px;
$avatar-font-family: inherit;
$avatar-font-size: 15px;
$avatar-font-weight: normal;
$avatar-line-height: inherit !default;
$avatar-margin: 0px 0px 0px 0px;

$balance-color: $header-font-color;
$balance-font-family: inherit;
$balance-font-weight: normal;
$balance-font-size: 16px;
$balance-line-height: 1;
$balance-padding: 0px 0px 0px 10px;
$balance-margin: 0px 15px 0px 0px;
$balance-text-align: center;

$balance-value-color: $header-font-color;
$balance-value-font-family: inherit;
$balance-value-font-weight: normal;
$balance-value-font-size: 30px;
$balance-value-line-height: 1;
$balance-value-padding: 0px;
$balance-value-margin: 0px;

@import '../../../app/q360-comps/account-display/src/account-display.scss';

$account-tile-color: white;
$account-tile-background-color: #848383;
$account-tile-margin: ($site-content-vert-padding * -1) ($site-content-horz-padding * -1.2)
  $site-content-vert-padding ($site-content-horz-padding * -1.2);
$account-tile-padding: 32px 0px;
$account-tile-box-shadow: none;

$account-tile-avatar-height: 100px;
$account-tile-avatar-width: 100px;
$account-tile-avatar-color: $header-font-color;
$account-tile-avatar-background-color: black;
$account-tile-avatar-border-radius: 50px;
$account-tile-avatar-font-family: inherit;
$account-tile-avatar-font-size: 48px;
$account-tile-avatar-font-weight: normal;
$account-tile-avatar-margin: 0px 10px 0px 10px;

$account-tile-balance-color: $header-font-color;
$account-tile-balance-font-family: inherit;
$account-tile-balance-font-weight: normal;
$account-tile-balance-font-size: 25px;
$account-tile-balance-line-height: 1;
$account-tile-balance-padding: 0px;
$account-tile-balance-margin: 0px 10px 0px 10px;
$account-tile-balance-text-align: center;

$account-tile-balance-value-color: $header-font-color;
$account-tile-balance-value-font-family: inherit;
$account-tile-balance-value-font-weight: normal;
$account-tile-balance-value-font-size: 48px;
$account-tile-balance-value-line-height: 1;
$account-tile-balance-value-padding: 0px;
$account-tile-balance-value-margin: 0px;

@import '../../../app/q360-comps/account-display-tile/src/account-display-tile.scss';
