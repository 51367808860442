.main-nav {
  .icon {
    text-align: left;
  }

  .subnav {
    position: fixed;
    top: 64px;
    height: calc(100vh - 133px);
    background-color: $white;
    border-top: 1.5rem solid $brand-primary-color;
    right: inherit;
    left: 0;
    padding: 0px;
    margin: 0px;
    box-shadow: 15px 0px 25px -15px $med-dark-grey;

    > div {
      // the first div inside the subnav also needs the background color set
      background-color: $white;
      height: calc(100vh - 133px); // header + dock => 64px + 69px = 133px

      @media screen and (min-width: 768px) {
        height: calc(100vh - 149px); // header + dock => 80px + 69px = 133px
      }
      @media screen and (min-width: 1024px) {
        height: calc(100vh - 82px);
      }
    }

    @media screen and (min-width: 768px) {
      // this is when the top menu changes
      top: 80px;
      height: calc(100vh - 149px);
    }

    @media screen and (min-width: 1024px) {
      // switch to desktop layout
      left: inherit;
      right: 0px;
      height: calc(100vh - 82px);
      box-shadow: -15px 0px 25px -15px $med-dark-grey;
      overflow-x: hidden;
    }

    .subnav-user-info {
      background-color: $brand-primary-color;
      height: 11rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .points-display {
        border: none;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        order: 1;

        .balance {
          text-align: left;

          a {
            text-align: left;
            margin: 0;
            padding: 0;
            font-size: 13px;
            &:hover {
              background-color: initial;
            }
          }
          & * {
            color: $white !important; // all text in avatar container
          }
          &:before {
            display: none; // star with circle icone
          }
          .profile-user-name {
            display: block;
            margin-bottom: 0.5rem;
            span {
              display: block;
              line-height: 1.25;
            }
          }

          .val {
            font-size: 21px;
            &:after {
              content: ' ';
              width: 2px;
              display: inline-block;
            }
          }
        }
      }

      .avatar {
        width: 100px;
        height: 100px;
        border: 5px solid $white;
        border-radius: 55px;
        box-sizing: border-box;
        a {
          height: 100%;
          width: 100%;
          &:hover {
            background: none;
          }
          &:link {
            padding: 0;
          }
        }
      }

      .balance {
        a {
          min-width: auto;
        }
      }
    }
    a.nav-list-item-link {
      color: $near-black;
      &:hover {
        color: $brand-primary-color;
      }
    }

    ul {
      box-shadow: none;
      width: 295px;
      background-color: #ffffff;
    }
    * + * {
      border-top: 0;
    }

    a,
    a:link {
      font-size: 16px;
      padding: 1rem;
      height: auto;
      width: 100%;
      &:hover {
        .icon {
          color: #000000;
        }
      }
      &:hover {
        background-color: #ededed;
      }
    }
  }
}
.subnav-overlay {
  //background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  position: fixed;
  right: 0;
  top: 64px;
  height: calc(100vh - 133px); // 64px header and 69px dock for mobile
  z-index: -1;
  @media screen and (min-width: 768px) {
    // tablet
    top: 80px;
    height: calc(100vh - 144px); // 80px header and 69px dock
  }
  @media screen and (min-width: 1024px) {
    // when on desktop move overlay to the left
    left: 0;
    height: calc(100vh - 80px); // 80px header
    position: absolute;
  }
}
