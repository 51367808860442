//site colors by name for use in THIS FILE ONLY!
//These names should only be used for convience in THIS file.
//All color names in other sass files should be ambigious,
//related to their use on the site, not their value.

// $profile-pic-gray: rgb(110, 110, 110); // based on profile picture circle/initials
// $med-grey2:       white; // based on primary button ("Get Started" in doc and "Next" in Demo-Skin)

// // was $white
// $pink:           rgb(205, 36, 129); // gray if based on header text color OR pink if based on primary button text color?
$brand-primary-color: #cb177d;
$font-pink: $brand-primary-color;
$button-pink: $brand-primary-color;

$font-grey: rgb(110, 110, 110);
$dark-grey: rgb(110, 110, 110);

$white: #ffffff;
$very-light-grey: rgb(246, 246, 246); // #f6f6f6
$light-grey: #cfcfcf;
$light-grey2: #bfbfbf;
$light-grey3: #a0a0a0;
$med-light-grey: #949494;
$med-light-grey2: #858585;
$med-grey: #6a6a6a;
$med-grey2: #616161;
$med-dark-grey: #575757;
$dark-grey: #292929;
$near-black: #171717;
$black: #000000;
$off-grey: #989696;

// TODO: refactor some once theme is almost done

//Color palette lists.  Colors should be ordered lightest to darkest.
//primary palette is usually logo colors.
// $palette-primary: $white, $light-grey3, $black; // ... not used anywhere, right now

//background palette for colors used as the backgrounds of elements.
$palette-backgrounds: $white, $light-grey2, $med-grey, $med-grey2, $dark-grey;

//palette for font colors
$palette-font-colors: $white, $light-grey2, $med-light-grey, $near-black;

//palette for button colors/states
$palette-button-colors: $white; // for sure, button background..

// $some-var: new?; // what_we_should_use; // $old-actual-color

//Base Colors
$body-background-color: $very-light-grey; //nth($palette-backgrounds, 2); // $light-grey2
$header-background-color: $white; // nth($palette-backgrounds, 3); // $med-grey
$footer-background-color: nth($palette-backgrounds, 5); // $dark-grey

//Header
$header-font-color: #6e6e6e; //nth($palette-font-colors, 1); // $white
$header-divider-color: $med-light-grey;
$header-subnav-background-color: nth($palette-backgrounds, 1); // $white
$header-subnav-hover-background-color: nth($palette-backgrounds, 5); // $dark-grey
$header-subnav-shadow-color: rgba($black, 0.13);
$header-subnav-divider-color: rgba($black, 0.13);
$header-subnav-font-color: nth($palette-font-colors, 4); // $near-black
$header-subnav-hover-font-color: nth($palette-font-colors, 1); // $white
$icon-color: $brand-primary-color;

//Footer
$footer-font-color: nth($palette-font-colors, 1); // $white
$footer-link-color: nth($palette-font-colors, 2); // $light-grey2

//Flag
$flag-color: nth($palette-font-colors, 1); // $white
$flag-background-color: nth($palette-backgrounds, 3); // $med-grey
$flag-shadow-color: rgba($black, 0.8);

//Meters
$meter-title-font-color: nth($palette-font-colors, 4); // $near-black
$meter-asof-font-color: nth($palette-font-colors, 3); // $med-light-grey
$meter-display-font-color: nth($palette-font-colors, 4); // $near-black
$meter-tile-bg-color: nth($palette-backgrounds, 1); // $white
