$focus-color: #5b9dd9 !default;
$focus-outline: none !default;
$focus-box-shadow: 0 0 3px 1px $focus-color !default;

$slide-color: black !default;
$slide-background-color: white !default;
$slide-font-family: inherit !default;
$slide-font-size: 15px !default;
$slide-font-weight: normal !default;
$slide-line-height: inherit !default;
$slide-margin: 0px !default;

$slide-page-color: white !default;
$slide-page-active-color: white !default;
$slide-page-complete-color: white !default;
$slide-page-complete-active-color: white !default;
$slide-page-background-color: #b5b5b5 !default;
$slide-page-active-background-color: black !default;
$slide-page-complete-background-color: #00b000 !default;
$slide-page-complete-active-background-color: #006000 !default;
$slide-page-border-radius: 16px !default;

$slide-page-height: 16px !default;
$slide-page-width: 16px !default;
$slide-page-font-family: $slide-font-family !default;
$slide-page-font-size: 15px !default;
$slide-page-font-weight: normal !default;
$slide-page-line-height: $slide-line-height !default;
$slide-page-margin: 0px 10px 20px 0px !default;

$slide-arrow-color: black !default;
$slide-arrow-width: 30px !default;
$slide-arrow-height: 40px !default;
$slide-arrow-thickness: 4px !default;
$slide-arrow-margin: 0px 20px !default;

$slide-duration: 300ms !default;
$slide-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

$carousel-hover-duration: 200ms !default;
$carousel-hover-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;

.carousel {
  position: relative;
  overflow-x: hidden;

  .slide-container {
    display: flex;
    transition: $slide-duration $slide-easing left;

    &.user-slide {
      transition: none;
    }

    .slide {
      width: 100%;
      display: block;
      color: $slide-color;
      background-color: $slide-background-color;
      font-family: $slide-font-family;
      font-size: $slide-font-size;
      font-weight: $slide-font-weight;
      line-height: $slide-line-height;
      padding: $slide-margin;
      box-sizing: border-box;
    }
  }

  ul.pager {
    opacity: 0;
    list-style: none;
    margin: 0px 0px auto 0px;
    padding: 0px;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0px;
    transition: opacity $carousel-hover-duration $carousel-hover-easing;

    li {
      display: inline-block;
      height: $slide-page-height;
      width: $slide-page-width;
      text-align: center;
      padding: 0px;
      margin: $slide-page-margin;
      font-family: $slide-page-font-family;
      font-size: $slide-page-font-size;
      font-weight: $slide-page-font-weight;
      line-height: $slide-page-line-height;
      vertical-align: middle;

      a {
        text-decoration: none;
        display: block;
        cursor: pointer;
        border-radius: $slide-page-border-radius;
        color: $slide-page-color;
        font-family: $slide-font-family;
        font-size: $slide-page-font-size;
        font-weight: $slide-page-font-weight;
        line-height: $slide-page-line-height;
        background-color: $slide-page-background-color;
        width: 100%;
        height: 100%;

        &:focus {
          outline: $focus-outline;
          box-shadow: $focus-box-shadow;
        }
      }

      &.active {
        a {
          color: $slide-page-active-color;
          background-color: $slide-page-active-background-color;
        }
      }

      &.complete {
        a {
          color: $slide-page-complete-color;
          background-color: $slide-page-complete-background-color;
        }

        &.active {
          a {
            color: $slide-page-complete-active-color;
            background-color: $slide-page-complete-active-background-color;
          }
        }
      }
    }
  }

  button.prev {
    opacity: 0;
    position: absolute;
    left: 0px;
    top: calc(50% - (#{$slide-arrow-height} / 2));
    width: $slide-arrow-width;
    height: $slide-arrow-height;
    border: none;
    padding: 0px;
    margin: $slide-arrow-margin;
    background: transparent;
    cursor: pointer;
    transition: opacity $carousel-hover-duration $carousel-hover-easing;

    &:focus {
      outline: $focus-outline;
      box-shadow: $focus-box-shadow;
    }

    span:after {
      display: inline-block;
      content: '';
      width: (calc($slide-arrow-width / 30) * 16);
      height: (calc($slide-arrow-height / 40) * 16);
      border-left: $slide-arrow-color $slide-arrow-thickness solid;
      border-bottom: $slide-arrow-color $slide-arrow-thickness solid;
      transform: rotate(45deg);
      position: relative;
      left: 3px;
      top: 1px;
      transition: opacity $carousel-hover-duration $carousel-hover-easing;
    }
  }

  button.next {
    opacity: 0;
    position: absolute;
    right: 0px;
    top: calc(50% - (#{$slide-arrow-height} / 2));
    width: $slide-arrow-width;
    height: $slide-arrow-height;
    padding: 0px;
    margin: $slide-arrow-margin;
    border: none;
    background: transparent;
    cursor: pointer;

    &:focus {
      outline: $focus-outline;
      box-shadow: $focus-box-shadow;
    }

    span:after {
      display: inline-block;
      content: '';
      width: (calc($slide-arrow-width / 30) * 16);
      height: (calc($slide-arrow-height / 40) * 16);
      border-right: $slide-arrow-color $slide-arrow-thickness solid;
      border-bottom: $slide-arrow-color $slide-arrow-thickness solid;
      transform: rotate(-45deg);
      position: relative;
      left: -8px;
      top: 1px;
    }
  }

  &:hover {
    ul.pager {
      opacity: 1;
    }

    button.prev {
      opacity: 1;
    }

    button.next {
      opacity: 1;
    }
  }

  .slide-animate-enter {
    transition: all $slide-duration $slide-easing;
  }

  .slide-animate-exit {
    transition: all $slide-duration $slide-easing;
  }

  .slider-counter-circle-container {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }

  .slider-counter-circle {
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    border: 2px solid $med-light-grey;
    border-radius: 50%;
    margin: 0 0.33rem;
    &.active {
      background: $dark-grey;
    }
  }
}
