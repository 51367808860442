//*******************************************************************************************/
//************** layout/overlay.scss - the starting file for global-defaults ****************/
//*******************************************************************************************/
.tile.standalone .board-container .promotion .overlay-container .overlay {
  &.challenge-complete {
    background-color: #cb117d;

    .activity-text-content {
      position: relative;
      z-index: 22;
      .content-container {
        .content {
          background-color: #cb117d;
          .payout {
            .text {
              color: #fff;

              .earnedPts {
                color: #fff;
              }

              .challengeCompleteText {
                margin-top: 160px;
                margin-bottom: 0;
                font-size: 21px;
                text-align: left;
                color: #fff;
                @media screen and (min-width: 768px) {
                  text-align: center;
                  margin-top: 60px;
                }
                @media screen and (min-width: 1024px) {
                  text-align: left;
                  margin-top: 20px;
                }
              }
            }
          }
          .button.primary {
            background-color: #fff;
            span {
              color: #cb117d;
            }
          }
          .cta-btns {
            .nonstandard.next-activity.pink-background-styles {
              background-color: #fff;
              span {
                color: #cb117d;
              }
            }

            .secondary.nonstandard.not-now {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.overlay {
  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bubble-image {
    svg.success-checkmark {
      width: 160px;
      height: 160px;
      @media screen and (min-width: 768px) {
        width: 200px;
        height: 200px;
      }
      @media screen and (min-width: 1024px) {
        width: 260px;
        height: 260px;
      }
    }
    @media screen and (min-width: 1024px) {
      width: 40%;
    }
  }

  .activity-text-content {
    @media screen and (min-width: 1024px) {
      width: 50%;
      text-align: left;
    }

    .content-container {
      .content {
        .actComplete {
          margin: 0;
          text-transform: uppercase;
          font-size: 13px;
        }

        .earnedPts {
          color: #cb117d;
          @media screen and (min-width: 768px) {
            font-size: 34px;
          }
        }

        .challengePoints {
          font-size: 24px;
          @media screen and (min-width: 768px) {
            font-size: 56px;
          }
        }
        @media screen and (min-width: 1024px) {
          padding: 0 2.5rem 2rem 2.5rem;
        }
      }
    }

    .cta-btns {
      @media screen and (min-width: 1024px) {
        margin-top: 1rem;
        display: flex;
      }

      .nonstandard.next-activity {
        margin: 1rem auto;
        // width: 34%;
        @media screen and (min-width: 1024px) {
          margin: 0;
        }
      }

      .secondary.nonstandard.not-now {
        margin: 1rem auto;
        // width: 34%;
        @media screen and (min-width: 1024px) {
          margin: 0 0 0 2rem;
        }
      }
    }
  }

  button {
    @media screen and (max-width: 1023px) {
      margin: 1rem auto;
    }
  }
}

.overlay-container .overlay img.complete {
  z-index: 30;
  position: relative;
  visibility: hidden;
  animation: fade-in-up 1s cubic-bezier(0.28, 0.84, 0.42, 1) 0.25s 1 normal forwards;
}

// Issue #570 - Setting overlay to scroll if needed but only in the single block promotion where it was reported that CTA buttons were not visible in payout overlay.
// It is not totally safe for all overlays to scroll as there are instances like challenge-complete where the canvas width is wider than the overlay and causing a double vertical scrollbar in addition to an unwanted horizontal scrollbar.
.promotion.single_block .overlay-container .overlay {
  overflow: auto;
}

.bubble {
  background-color: rgba(203, 23, 125, 0.9);
  display: inline-block;
  width: 0px;
  height: 0px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  pointer-events: none; // Ideally bubbles would render under UI elements, making this property unnecessary
}

@mixin animateProps-1 {
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-delay: 0.75s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@mixin animateProps-2 {
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-delay: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}

@mixin mobilePosition {
  position: absolute;
  left: 48vw;
  top: 24vh;
}

@mixin desktopPosition {
  @media screen and (min-width: 1024px) {
    position: absolute;
    left: 24vw;
    top: 56vh;
  }
}

@mixin bubbleSize {
  width: 40px;
  height: 40px;
}

//cubic-bezier(0.175, 0.885, 0.32, 1.275)
.bubble-1 {
  opacity: 0.56;
  animation: move-1;
}

.bubble-2 {
  opacity: 0.53;
  animation: move-2;
}

.bubble-3 {
  opacity: 0.79;
  visibility: hidden;
  transform: translate(-43vw, 10vh) scale(0.5);
  animation: move-3;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-4 {
  opacity: 0.05;
  animation: move-4;
}

.bubble-5 {
  opacity: 0.03;
  animation: move-5;
}

.bubble-6 {
  opacity: 0.73;
  animation: move-6;
}

.bubble-7 {
  opacity: 0.4;
  visibility: hidden;
  transform: translate(-12vw, 30vh) scale(0.14);
  animation: move-7;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-8 {
  opacity: 0.36;
  visibility: hidden;
  transform: translate(-21vw, 34vh) scale(0.1);
  animation: move-8;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-9 {
  opacity: 0.82;
  animation: move-9;
}

.bubble-10 {
  opacity: 0.84;
  visibility: hidden;
  transform: translate(8vw, 37vh) scale(0.4);
  animation: move-10;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-11 {
  opacity: 0.52;
  animation: move-11;
}

.bubble-12 {
  opacity: 0.64;
  animation: move-12;
}

.bubble-13 {
  opacity: 0.65;
  animation: move-13;
}

.bubble-14 {
  opacity: 0.58;
  animation: move-14;
}

.bubble-15 {
  opacity: 0.09;
  animation: move-15;
}

.bubble-16 {
  opacity: 0.14;
  visibility: hidden;
  transform: translate(-40vw, 42vh) scale(0.39);
  animation: move-16;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-17 {
  opacity: 0.73;
  animation: move-17;
}

.bubble-18 {
  opacity: 0.61;
  animation: move-18;
}

.bubble-19 {
  opacity: 0.05;
  animation: move-19;
}

.bubble-20 {
  opacity: 0.26;
  animation: move-20;
}

.bubble-21 {
  opacity: 0.47;
  width: 40px;
  height: 40px;
  visibility: hidden;
  transform: translate(8vw, -25vh) scale(0.41);
  animation: move-21;
  @include mobilePosition;
  @include animateProps-2;
}

.bubble-22 {
  opacity: 0.11;
  animation: move-22;
}

.bubble-23 {
  opacity: 0.9;
  animation: move-23;
}

.bubble-24 {
  opacity: 0.21;
  animation: move-24;
}

.bubble-25 {
  opacity: 0.48;
  animation: move-25;
}

.bubble-26 {
  opacity: 0.23;
  animation: move-26;
}

.bubble-27 {
  opacity: 0.08;
  animation: move-27;
}

.bubble-28 {
  opacity: 0.54;
  animation: move-28;
}

.bubble-29 {
  opacity: 0.45;
  animation: move-29;
}

.bubble-30 {
  opacity: 0.95;
  animation: move-30;
}

.bubble-31 {
  opacity: 0.56;
  animation: move-31;
}

.bubble-32 {
  opacity: 0.53;
  animation: move-32;
}

.bubble-33 {
  opacity: 0.79;
  visibility: hidden;
  transform: translate(-23vw, 10vh) scale(0.5);
  animation: move-33;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-34 {
  opacity: 0.05;
  animation: move-34;
}

.bubble-35 {
  opacity: 0.03;
  animation: move-35;
}

.bubble-36 {
  opacity: 0.73;
  animation: move-36;
}

.bubble-37 {
  opacity: 0.4;
  visibility: hidden;
  transform: translate(-12vw, 45vh) scale(0.14);
  animation: move-37;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-38 {
  opacity: 0.36;
  visibility: hidden;
  transform: translate(21vw, 34vh) scale(0.1);
  animation: move-38;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-39 {
  opacity: 0.82;
  animation: move-39;
}

.bubble-40 {
  opacity: 0.84;
  visibility: hidden;
  transform: translate(8vw, 37vh) scale(0.4);
  animation: move-10;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-41 {
  opacity: 0.28;
  animation: move-41;
}

.bubble-42 {
  opacity: 0.64;
  animation: move-42;
}

.bubble-43 {
  opacity: 0.65;
  animation: move-43;
}

.bubble-44 {
  opacity: 0.58;
  animation: move-44;
}

.bubble-45 {
  opacity: 0.09;
  animation: move-45;
}

.bubble-46 {
  opacity: 0.14;
  visibility: hidden;
  transform: translate(32vw, 42vh) scale(0.39);
  animation: move-46;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-47 {
  opacity: 0.73;
  animation: move-47;
}

.bubble-48 {
  opacity: 0.61;
  animation: move-48;
}

.bubble-49 {
  opacity: 0.05;
  animation: move-49;
}

.bubble-50 {
  opacity: 0.26;
  animation: move-50;
}

.bubble-51 {
  opacity: 0.47;
  width: 40px;
  height: 40px;
  visibility: hidden;
  transform: translate(33vw, -20vh) scale(0.41);
  animation: move-51;
  @include mobilePosition;
  @include animateProps-2;
}

.bubble-52 {
  opacity: 0.11;
  animation: move-52;
}

.bubble-53 {
  opacity: 0.9;
  animation: move-53;
}

.bubble-54 {
  opacity: 0.21;
  animation: move-54;
}

.bubble-55 {
  opacity: 0.48;
  animation: move-55;
}

.bubble-56 {
  opacity: 0.23;
  animation: move-56;
}

.bubble-57 {
  opacity: 0.08;
  animation: move-57;
}

.bubble-58 {
  opacity: 0.44;
  animation: move-58;
}

.bubble-59 {
  opacity: 0.45;
  animation: move-59;
}

.bubble-60 {
  opacity: 0.5;
  animation: move-60;
}

.bubble-61 {
  opacity: 0.56;
  animation: move-61;
}

.bubble-62 {
  opacity: 0.53;
  animation: move-62;
}

.bubble-63 {
  opacity: 0.79;
  animation: move-63;
}

.bubble-64 {
  opacity: 0.3;
  animation: move-64;
}

.bubble-65 {
  opacity: 0.03;
  animation: move-65;
}

.bubble-66 {
  opacity: 0.73;
  animation: move-66;
}

.bubble-67 {
  opacity: 0.4;
  visibility: hidden;
  transform: translate(-46vw, 50vh) scale(0.14);
  animation: move-67;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-68 {
  opacity: 0.8;
  animation: move-68;
}

.bubble-69 {
  opacity: 0.82;
  animation: move-69;
}

.bubble-70 {
  opacity: 0.84;
  visibility: hidden;
  transform: translate(30vw, 37vh) scale(1.1);
  animation: move-70;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-71 {
  opacity: 0.72;
  animation: move-71;
}

.bubble-72 {
  opacity: 0.64;
  animation: move-72;
}

.bubble-73 {
  opacity: 0.65;
  animation: move-73;
}

.bubble-74 {
  opacity: 0.58;
  animation: move-74;
}

.bubble-75 {
  opacity: 0.21;
  animation: move-75;
}

.bubble-76 {
  opacity: 0.14;
  visibility: hidden;
  transform: translate(-50vw, 0vh) scale(0.39);
  animation: move-76;
  @include bubbleSize;
  @include animateProps-2;
}

.bubble-77 {
  opacity: 0.73;
  animation: move-77;
}

.bubble-78 {
  opacity: 0.61;
  animation: move-78;
}

.bubble-79 {
  opacity: 0.05;
  animation: move-79;
}

.bubble-80 {
  opacity: 0.26;
  animation: move-80;
}

.bubble-81 {
  opacity: 0.09;
  animation: move-81;
}

.bubble-82 {
  opacity: 0.19;
  animation: move-82;
}

.bubble-83 {
  opacity: 0.9;
  animation: move-83;
}

.bubble-84 {
  opacity: 0.1;
  animation: move-84;
}

.bubble-85 {
  opacity: 0.48;
  animation: move-85;
}

.bubble-86 {
  opacity: 0.23;
  animation: move-86;
}

.bubble-87 {
  opacity: 0.08;
  animation: move-87;
}

.bubble-88 {
  opacity: 0.54;
  animation: move-88;
}

.bubble-89 {
  opacity: 0.45;
  animation: move-89;
}

.bubble-90 {
  opacity: 0.95;
  animation: move-90;
}

.bubble-1,
.bubble-2,
.bubble-4,
.bubble-5,
.bubble-6,
.bubble-9,
.bubble-11,
.bubble-12,
.bubble-13,
.bubble-14,
.bubble-15,
.bubble-17,
.bubble-18,
.bubble-19,
.bubble-20,
.bubble-22,
.bubble-23,
.bubble-24,
.bubble-25,
.bubble-26,
.bubble-27,
.bubble-28,
.bubble-29,
.bubble-30,
.bubble-31,
.bubble-32,
.bubble-34,
.bubble-35,
.bubble-36,
.bubble-39,
.bubble-41,
.bubble-42,
.bubble-43,
.bubble-44,
.bubble-45,
.bubble-47,
.bubble-48,
.bubble-49,
.bubble-50,
.bubble-52,
.bubble-53,
.bubble-54,
.bubble-55,
.bubble-56,
.bubble-57,
.bubble-58,
.bubble-59,
.bubble-60,
.bubble-61,
.bubble-62,
.bubble-63,
.bubble-64,
.bubble-65,
.bubble-66,
.bubble-68,
.bubble-69,
.bubble-71,
.bubble-72,
.bubble-73,
.bubble-74,
.bubble-75,
.bubble-77,
.bubble-78,
.bubble-79,
.bubble-80,
.bubble-81,
.bubble-82,
.bubble-83,
.bubble-84,
.bubble-85,
.bubble-86,
.bubble-87,
.bubble-88,
.bubble-89,
.bubble-90 {
  @include mobilePosition;
  @include animateProps-1;
  @include desktopPosition;
}

@keyframes move-1 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-28vw, -14vh) scale(0.22);
    -webkit-transform: translate(-28vw, -14vh) scale(0.22);
    transform: translate(-28vw, -14vh) scale(0.22);
  }
}
@keyframes move-2 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-35vw, -25vh) scale(0.36);
    -webkit-transform: translate(-35vw, -25vh) scale(0.36);
    transform: translate(-35vw, -25vh) scale(0.36);
  }
}
@keyframes move-3 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(-43vw, 14vh) scale(0.5);
    -webkit-transform: translate(-43vw, 14vh) scale(0.5);
    transform: translate(-43vw, 14vh) scale(0.5);
  }
}
@keyframes move-4 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-20vw, -25vh) scale(1.2);
    -webkit-transform: translate(-20vw, -25vh) scale(1.2);
    transform: translate(-20vw, -25vh) scale(1.2);
  }
}
@keyframes move-5 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-17vw, -48vh) scale(0.45);
    -webkit-transform: translate(-17vw, -48vh) scale(0.45);
    transform: translate(-17vw, -48vh) scale(0.45);
  }
}
@keyframes move-6 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-3vw, 15vh) scale(0.05);
    -webkit-transform: translate(-3vw, 15vh) scale(0.05);
    transform: translate(-3vw, 15vh) scale(0.05);
  }
}
@keyframes move-7 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(-12vw, 31vh) scale(0.14);
    -webkit-transform: translate(-12vw, 31vh) scale(0.14);
    transform: translate(-12vw, 31vh) scale(0.14);
  }
}
@keyframes move-8 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(-27vw, 32vh) scale(0.1);
    -webkit-transform: translate(-27vw, 32vh) scale(0.1);
    transform: translate(-27vw, 32vh) scale(0.1);
  }
}
@keyframes move-9 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-37vw, 19vh) scale(0.22);
    -webkit-transform: translate(-37vw, 19vh) scale(0.22);
    transform: translate(-37vw, 19vh) scale(0.22);
  }
}
@keyframes move-10 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(6vw, 40vh) scale(0.4);
    -webkit-transform: translate(6vw, 40vh) scale(0.4);
    transform: translate(6vw, 40vh) scale(0.4);
  }
}
@keyframes move-11 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-80vw, -9vh) scale(0.36);
    -webkit-transform: translate(-80vw, -9vh) scale(0.36);
    transform: translate(-80vw, -9vh) scale(0.36);
  }
}
@keyframes move-12 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-60vw, -53vh) scale(0.09);
    -webkit-transform: translate(-60vw, -53vh) scale(0.09);
    transform: translate(-60vw, -53vh) scale(0.09);
  }
}
@keyframes move-13 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-36vw, -44vh) scale(0.28);
    -webkit-transform: translate(-36vw, -44vh) scale(0.28);
    transform: translate(-36vw, -44vh) scale(0.28);
  }
}
@keyframes move-14 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(23vw, -15vh) scale(0.36);
    -webkit-transform: translate(23vw, -15vh) scale(0.36);
    transform: translate(23vw, -15vh) scale(0.36);
  }
}
@keyframes move-15 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(19vw, -19vh) scale(0.4);
    -webkit-transform: translate(19vw, -19vh) scale(0.4);
    transform: translate(19vw, -19vh) scale(0.4);
  }
}
@keyframes move-16 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(-42vw, 42vh) scale(0.39);
    -webkit-transform: translate(-42vw, 42vh) scale(0.39);
    transform: translate(-42vw, 42vh) scale(0.39);
  }
}
@keyframes move-17 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(30vw, 11vh) scale(0.2);
    -webkit-transform: translate(30vw, 11vh) scale(0.2);
    transform: translate(30vw, 11vh) scale(0.2);
  }
}
@keyframes move-18 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-60vw, 13vh) scale(0.17);
    -webkit-transform: translate(-60vw, 13vh) scale(0.17);
    transform: translate(-60vw, 13vh) scale(0.17);
  }
}
@keyframes move-19 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(20vw, 0vh) scale(0.25);
    -webkit-transform: translate(20vw, 0vh) scale(0.25);
    transform: translate(20vw, 0vh) scale(0.25);
  }
}
@keyframes move-20 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(30vw, -1vh) scale(0.32);
    -webkit-transform: translate(30vw, -1vh) scale(0.32);
    transform: translate(30vw, -1vh) scale(0.32);
  }
}
@keyframes move-21 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(4vw, -25vh) scale(0.41);
    -webkit-transform: translate(4vw, -25vh) scale(0.41);
    transform: translate(4vw, -25vh) scale(0.41);
  }
}
@keyframes move-22 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(40vw, -23vh) scale(2);
    -webkit-transform: translate(40vw, -23vh) scale(2);
    transform: translate(40vw, -23vh) scale(2);
  }
}
@keyframes move-23 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(39vw, -10vh) scale(0.3);
    -webkit-transform: translate(39vw, -10vh) scale(0.3);
    transform: translate(39vw, -10vh) scale(0.3);
  }
}
@keyframes move-24 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-30vw, 5vh) scale(0.99);
    -webkit-transform: translate(-30vw, 5vh) scale(0.99);
    transform: translate(-30vw, 5vh) scale(0.99);
  }
}
@keyframes move-25 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(36vw, 7vh) scale(0.13);
    -webkit-transform: translate(36vw, 7vh) scale(0.13);
    transform: translate(36vw, 7vh) scale(0.13);
  }
}
@keyframes move-26 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(15vw, -33vh) scale(0.27);
    -webkit-transform: translate(15vw, -33vh) scale(0.27);
    transform: translate(15vw, -33vh) scale(0.27);
  }
}
@keyframes move-27 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-54vw, -10vh) scale(1.5);
    -webkit-transform: translate(-54vw, -10vh) scale(1.5);
    transform: translate(-54vw, -10vh) scale(1.5);
  }
}
@keyframes move-28 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(22vw, -47vh) scale(0.44);
    -webkit-transform: translate(22vw, -47vh) scale(0.44);
    transform: translate(22vw, -47vh) scale(0.44);
  }
}
@keyframes move-29 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(2vw, -45vh) scale(0.39);
    -webkit-transform: translate(2vw, -45vh) scale(0.39);
    transform: translate(2vw, -45vh) scale(0.39);
  }
}
@keyframes move-30 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(42vw, -9vh) scale(0.5);
    -webkit-transform: translate(42vw, -9vh) scale(0.5);
    transform: translate(42vw, -9vh) scale(0.5);
  }
}
@keyframes move-31 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-36vw, -5vh) scale(0.22);
    -webkit-transform: translate(-36vw, -5vh) scale(0.22);
    transform: translate(-36vw, -5vh) scale(0.22);
  }
}
@keyframes move-32 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-35vw, 15vh) scale(0.36);
    -webkit-transform: translate(-35vw, 15vh) scale(0.36);
    transform: translate(-35vw, 15vh) scale(0.36);
  }
}
@keyframes move-33 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(-20vw, 7vh) scale(0.5);
    -webkit-transform: translate(-20vw, 7vh) scale(0.5);
    transform: translate(-20vw, 7vh) scale(0.5);
  }
}
@keyframes move-34 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(15vw, -5vh) scale(0.42);
    -webkit-transform: translate(15vw, -5vh) scale(0.42);
    transform: translate(15vw, -5vh) scale(0.42);
  }
}
@keyframes move-35 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-12vw, -38vh) scale(0.45);
    -webkit-transform: translate(-12vw, -38vh) scale(0.45);
    transform: translate(-12vw, -38vh) scale(0.45);
  }
}
@keyframes move-36 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(3vw, 6vh) scale(0.05);
    -webkit-transform: translate(3vw, 6vh) scale(0.05);
    transform: translate(3vw, 6vh) scale(0.05);
  }
}
@keyframes move-37 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(-10vw, 46vh) scale(0.14);
    -webkit-transform: translate(-10vw, 46vh) scale(0.14);
    transform: translate(-10vw, 46vh) scale(0.14);
  }
}
@keyframes move-38 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(25vw, 30vh) scale(0.1);
    -webkit-transform: translate(25vw, 30vh) scale(0.1);
    transform: translate(25vw, 30vh) scale(0.1);
  }
}
@keyframes move-39 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-34vw, 3vh) scale(0.22);
    -webkit-transform: translate(-34vw, 3vh) scale(0.22);
    transform: translate(-34vw, 3vh) scale(0.22);
  }
}
@keyframes move-40 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(7vw, 38vh) scale(0.4);
    -webkit-transform: translate(7vw, 38vh) scale(0.4);
    transform: translate(7vw, 38vh) scale(0.4);
  }
}
@keyframes move-41 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-40vw, 0vh) scale(0.36);
    -webkit-transform: translate(-40vw, 0vh) scale(0.36);
    transform: translate(-40vw, 0vh) scale(0.36);
  }
}
@keyframes move-42 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-43vw, -27vh) scale(0.84);
    -webkit-transform: translate(-43vw, -27vh) scale(0.84);
    transform: translate(-43vw, -27vh) scale(0.84);
  }
}
@keyframes move-43 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-18vw, -22vh) scale(0.28);
    -webkit-transform: translate(-18vw, -22vh) scale(0.28);
    transform: translate(-18vw, -22vh) scale(0.28);
  }
}
@keyframes move-44 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(29vw, -3vh) scale(0.36);
    -webkit-transform: translate(29vw, -3vh) scale(0.36);
    transform: translate(29vw, -3vh) scale(0.36);
  }
}
@keyframes move-45 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(12vw, -12vh) scale(0.4);
    -webkit-transform: translate(12vw, -12vh) scale(0.4);
    transform: translate(12vw, -12vh) scale(0.4);
  }
}
@keyframes move-46 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(33vw, 43vh) scale(0.39);
    -webkit-transform: translate(33vw, 43vh) scale(0.39);
    transform: translate(33vw, 43vh) scale(0.39);
  }
}
@keyframes move-47 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(13vw, 9vh) scale(0.2);
    -webkit-transform: translate(13vw, 9vh) scale(0.2);
    transform: translate(13vw, 9vh) scale(0.2);
  }
}
@keyframes move-48 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-56vw, 16vh) scale(0.17);
    -webkit-transform: translate(-56vw, 16vh) scale(0.17);
    transform: translate(-56vw, 16vh) scale(0.17);
  }
}
@keyframes move-49 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(21vw, 3vh) scale(0.9);
    -webkit-transform: translate(21vw, 3vh) scale(0.9);
    transform: translate(21vw, 3vh) scale(0.9);
  }
}
@keyframes move-50 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(28vw, 5vh) scale(0.32);
    -webkit-transform: translate(28vw, 5vh) scale(0.32);
    transform: translate(28vw, 5vh) scale(0.32);
  }
}
@keyframes move-51 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(36vw, -18vh) scale(0.41);
    -webkit-transform: translate(36vw, -18vh) scale(0.41);
    transform: translate(36vw, -18vh) scale(0.41);
  }
}
@keyframes move-52 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(35vw, -5vh) scale(0.23);
    -webkit-transform: translate(35vw, -5vh) scale(0.23);
    transform: translate(35vw, -5vh) scale(0.23);
  }
}
@keyframes move-53 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-49vw, -16vh) scale(0.3);
    -webkit-transform: translate(-49vw, -16vh) scale(0.3);
    transform: translate(-49vw, -16vh) scale(0.3);
  }
}
@keyframes move-54 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-40vw, 25vh) scale(0.22);
    -webkit-transform: translate(-40vw, 25vh) scale(0.22);
    transform: translate(-40vw, 25vh) scale(0.22);
  }
}
@keyframes move-55 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-26vw, 17vh) scale(0.13);
    -webkit-transform: translate(-26vw, 17vh) scale(0.13);
    transform: translate(-26vw, 17vh) scale(0.13);
  }
}
@keyframes move-56 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-12vw, 17vh) scale(0.27);
    -webkit-transform: translate(-12vw, 17vh) scale(0.27);
    transform: translate(-12vw, 17vh) scale(0.27);
  }
}
@keyframes move-57 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(39vw, 23vh) scale(1.3);
    -webkit-transform: translate(39vw, 23vh) scale(1.3);
    transform: translate(39vw, 23vh) scale(1.3);
  }
}
@keyframes move-58 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-22vw, 18vh) scale(0.9);
    -webkit-transform: translate(-22vw, 18vh) scale(0.9);
    transform: translate(-22vw, 18vh) scale(0.9);
  }
}
@keyframes move-59 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-44vw, 5vh) scale(1.9);
    -webkit-transform: translate(-44vw, 5vh) scale(1.9);
    transform: translate(-44vw, 5vh) scale(1.9);
  }
}
@keyframes move-60 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(0vw, 12vh) scale(0.5);
    -webkit-transform: translate(0vw, 12vh) scale(0.5);
    transform: translate(0vw, 12vh) scale(0.5);
  }
}
@keyframes move-61 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-16vw, 5vh) scale(0.22);
    -webkit-transform: translate(-16vw, 5vh) scale(0.22);
    transform: translate(-16vw, 5vh) scale(0.22);
  }
}
@keyframes move-62 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-21vw, -2vh) scale(0.36);
    -webkit-transform: translate(-21vw, -2vh) scale(0.36);
    transform: translate(-21vw, -2vh) scale(0.36);
  }
}
@keyframes move-63 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-20vw, 7vh) scale(0.5);
    -webkit-transform: translate(-20vw, 7vh) scale(0.5);
    transform: translate(-20vw, 7vh) scale(0.5);
  }
}
@keyframes move-64 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(25vw, 25vh) scale(0.42);
    -webkit-transform: translate(25vw, 25vh) scale(0.42);
    transform: translate(25vw, 25vh) scale(0.42);
  }
}
@keyframes move-65 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-4vw, -17vh) scale(0.45);
    -webkit-transform: translate(-4vw, -17vh) scale(0.45);
    transform: translate(-4vw, -17vh) scale(0.45);
  }
}
@keyframes move-66 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(3vw, -18vh) scale(0.05);
    -webkit-transform: translate(3vw, -18vh) scale(0.05);
    transform: translate(3vw, -18vh) scale(0.05);
  }
}
@keyframes move-67 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(-48vw, 48vh) scale(0.14);
    -webkit-transform: translate(-48vw, 48vh) scale(0.14);
    transform: translate(-48vw, 48vh) scale(0.14);
  }
}
@keyframes move-68 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(35vw, -5vh) scale(0.3);
    -webkit-transform: translate(35vw, -5vh) scale(0.3);
    transform: translate(35vw, -5vh) scale(0.3);
  }
}
@keyframes move-69 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-44vw, 15vh) scale(0.22);
    -webkit-transform: translate(-44vw, 15vh) scale(0.22);
    transform: translate(-44vw, 15vh) scale(0.22);
  }
}
@keyframes move-70 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(29vw, 38vh) scale(1.1);
    -webkit-transform: translate(27vw, 38vh) scale(1.1);
    transform: translate(27vw, 38vh) scale(1.1);
  }
}
@keyframes move-71 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-49vw, -5vh) scale(0.36);
    -webkit-transform: translate(-49vw, -5vh) scale(0.36);
    transform: translate(-49vw, -5vh) scale(0.36);
  }
}
@keyframes move-72 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(18vw, 0vh) scale(0.09);
    -webkit-transform: translate(18vw, 0vh) scale(0.09);
    transform: translate(18vw, 0vh) scale(0.09);
  }
}
@keyframes move-73 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(8vw, 5vh) scale(0.28);
    -webkit-transform: translate(8vw, 5vh) scale(0.28);
    transform: translate(8vw, 5vh) scale(0.28);
  }
}
@keyframes move-74 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(43vw, 3vh) scale(0.36);
    -webkit-transform: translate(43vw, 3vh) scale(0.36);
    transform: translate(43vw, 3vh) scale(0.36);
  }
}
@keyframes move-75 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(22vw, -25vh) scale(0.49);
    -webkit-transform: translate(22vw, -25vh) scale(0.49);
    transform: translate(22vw, -25vh) scale(0.49);
  }
}
@keyframes move-76 {
  100% {
    @include bubbleSize;
    visibility: visible;
    -moz-transform: translate(-49vw, 0vh) scale(0.39);
    -webkit-transform: translate(-49vw, 0vh) scale(0.39);
    transform: translate(-49vw, 0vh) scale(0.39);
  }
}
@keyframes move-77 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(38vw, 20vh) scale(0.2);
    -webkit-transform: translate(38vw, 20vh) scale(0.2);
    transform: translate(38vw, 20vh) scale(0.2);
  }
}
@keyframes move-78 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-48vw, 10vh) scale(0.17);
    -webkit-transform: translate(-48vw, 10vh) scale(0.17);
    transform: translate(-48vw, 10vh) scale(0.17);
  }
}
@keyframes move-79 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(21vw, 17vh) scale(0.25);
    -webkit-transform: translate(21vw, 17vh) scale(0.25);
    transform: translate(21vw, 17vh) scale(0.25);
  }
}
@keyframes move-80 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-2vw, -18vh) scale(0.32);
    -webkit-transform: translate(-2vw, -18vh) scale(0.32);
    transform: translate(-2vw, -18vh) scale(0.32);
  }
}
@keyframes move-81 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(42vw, -15vh) scale(0.26);
    -webkit-transform: translate(42vw, -15vh) scale(0.26);
    transform: translate(42vw, -15vh) scale(0.26);
  }
}
@keyframes move-82 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(19vw, -5vh) scale(0.95);
    -webkit-transform: translate(19vw, -5vh) scale(0.95);
    transform: translate(19vw, -5vh) scale(0.95);
  }
}
@keyframes move-83 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(42vw, -25vh) scale(0.3);
    -webkit-transform: translate(42vw, -25vh) scale(0.3);
    transform: translate(42vw, -25vh) scale(0.3);
  }
}
@keyframes move-84 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-25vw, -8vh) scale(0.8);
    -webkit-transform: translate(-25vw, -8vh) scale(0.8);
    transform: translate(-25vw, -8vh) scale(0.8);
  }
}
@keyframes move-85 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-36vw, -17vh) scale(0.13);
    -webkit-transform: translate(-36vw, -17vh) scale(0.13);
    transform: translate(-36vw, -17vh) scale(0.13);
  }
}
@keyframes move-86 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(0vw, 10vh) scale(0.75);
    -webkit-transform: translate(0vw, 10vh) scale(0.75);
    transform: translate(0vw, 10vh) scale(0.75);
  }
}
@keyframes move-87 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(18vw, 17vh) scale(0.49);
    -webkit-transform: translate(18vw, 17vh) scale(0.49);
    transform: translate(18vw, 17vh) scale(0.49);
  }
}
@keyframes move-88 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(-20vw, 15vh) scale(0.35);
    -webkit-transform: translate(-20vw, 15vh) scale(0.35);
    transform: translate(-20vw, 15vh) scale(0.35);
  }
}
@keyframes move-89 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(42vw, 4vh) scale(0.59);
    -webkit-transform: translate(42vw, 4vh) scale(0.59);
    transform: translate(42vw, 4vh) scale(0.59);
  }
}
@keyframes move-90 {
  100% {
    @include bubbleSize;
    -moz-transform: translate(6vw, -18vh) scale(0.5);
    -webkit-transform: translate(6vw, -18vh) scale(0.5);
    transform: translate(6vw, -18vh) scale(0.5);
  }
}

@media screen and (min-width: 1024px) {
  @keyframes move-1 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(16vw, 24vh) scale(0.22);
      -webkit-transform: translate(16vw, 24vh) scale(0.22);
      transform: translate(16vw, 24vh) scale(0.22);
    }
  }
  @keyframes move-2 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(20vw, 14vh) scale(0.36);
      -webkit-transform: translate(20vw, 14vh) scale(0.36);
      transform: translate(20vw, 14vh) scale(0.36);
    }
  }
  @keyframes move-3 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-25vw, 8vh) scale(0.5);
      -webkit-transform: translate(-25vw, 8vh) scale(0.5);
      transform: translate(-25vw, 8vh) scale(0.5);
    }
  }
  @keyframes move-4 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-20vw, -31vh) scale(1.2);
      -webkit-transform: translate(-20vw, -31vh) scale(1.2);
      transform: translate(-20vw, -31vh) scale(1.2);
    }
  }
  @keyframes move-5 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-17vw, -4vh) scale(1.3);
      -webkit-transform: translate(-17vw, -4vh) scale(1.3);
      transform: translate(-17vw, -4vh) scale(1.3);
    }
  }
  @keyframes move-6 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-3vw, -9vh) scale(0.84);
      -webkit-transform: translate(-3vw, -9vh) scale(0.84);
      transform: translate(-3vw, -9vh) scale(0.84);
    }
  }
  @keyframes move-7 {
    100% {
      @include bubbleSize;
      visibility: visible;
      -moz-transform: translate(-12vw, 25vh) scale(0.14);
      -webkit-transform: translate(-12vw, 25vh) scale(0.14);
      transform: translate(-12vw, 25vh) scale(0.14);
    }
  }
  @keyframes move-8 {
    100% {
      @include bubbleSize;
      visibility: visible;
      -moz-transform: translate(-27vw, 26vh) scale(0.1);
      -webkit-transform: translate(-27vw, 26vh) scale(0.1);
      transform: translate(-27vw, 26vh) scale(0.1);
    }
  }
  @keyframes move-9 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-17vw, 13vh) scale(0.22);
      -webkit-transform: translate(-17vw, 13vh) scale(0.22);
      transform: translate(-17vw, 13vh) scale(0.22);
    }
  }
  @keyframes move-10 {
    100% {
      @include bubbleSize;
      visibility: visible;
      -moz-transform: translate(6vw, 34vh) scale(0.4);
      -webkit-transform: translate(6vw, 34vh) scale(0.4);
      transform: translate(6vw, 34vh) scale(0.4);
    }
  }
  @keyframes move-11 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-8vw, -15vh) scale(0.36);
      -webkit-transform: translate(-8vw, -15vh) scale(0.36);
      transform: translate(-8vw, -15vh) scale(0.36);
    }
  }
  @keyframes move-12 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-6vw, -31vh) scale(0.8);
      -webkit-transform: translate(-6vw, -31vh) scale(0.8);
      transform: translate(-6vw, -31vh) scale(0.8);
    }
  }
  @keyframes move-13 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(18vw, -10vh) scale(0.28);
      -webkit-transform: translate(18vw, -10vh) scale(0.28);
      transform: translate(18vw, -10vh) scale(0.28);
    }
  }
  @keyframes move-14 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(23vw, -29vh) scale(0.36);
      -webkit-transform: translate(23vw, -29vh) scale(0.36);
      transform: translate(23vw, -29vh) scale(0.36);
    }
  }
  @keyframes move-15 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(19vw, -25vh) scale(1.98);
      -webkit-transform: translate(19vw, -25vh) scale(1.98);
      transform: translate(19vw, -25vh) scale(1.98);
    }
  }
  @keyframes move-16 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-22vw, 36vh) scale(0.39);
      -webkit-transform: translate(-22vw, 36vh) scale(0.39);
      transform: translate(-22vw, 36vh) scale(0.39);
    }
  }
  @keyframes move-17 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(16vw, 5vh) scale(0.2);
      -webkit-transform: translate(16vw, 5vh) scale(0.2);
      transform: translate(16vw, 5vh) scale(0.2);
    }
  }
  @keyframes move-18 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-20vw, 7vh) scale(0.17);
      -webkit-transform: translate(-20vw, 7vh) scale(0.17);
      transform: translate(-20vw, 7vh) scale(0.17);
    }
  }
  @keyframes move-19 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(20vw, -6vh) scale(0.25);
      -webkit-transform: translate(20vw, -6vh) scale(0.25);
      transform: translate(20vw, -6vh) scale(0.25);
    }
  }
  @keyframes move-20 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(6vw, -26vh) scale(1.23);
      -webkit-transform: translate(6vw, -26vh) scale(1.23);
      transform: translate(6vw, -26vh) scale(1.23);
    }
  }
  @keyframes move-21 {
    100% {
      @include bubbleSize;
      visibility: hidden;
      -moz-transform: translate(3vw, -26vh) scale(0.41);
      -webkit-transform: translate(3vw, -26vh) scale(0.41);
      transform: translate(3vw, -26vh) scale(0.41);
    }
  }
  @keyframes move-22 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(17vw, -15vh) scale(1);
      -webkit-transform: translate(17vw, -15vh) scale(1);
      transform: translate(17vw, -15vh) scale(1);
    }
  }
  @keyframes move-23 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(9vw, -16vh) scale(0.3);
      -webkit-transform: translate(9vw, -16vh) scale(0.3);
      transform: translate(9vw, -16vh) scale(0.3);
    }
  }
  @keyframes move-24 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-14vw, -1vh) scale(0.99);
      -webkit-transform: translate(-14vw, -1vh) scale(0.99);
      transform: translate(-14vw, -1vh) scale(0.99);
    }
  }
  @keyframes move-25 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(6vw, 41vh) scale(0.13);
      -webkit-transform: translate(6vw, 41vh) scale(0.13);
      transform: translate(6vw, 41vh) scale(0.13);
    }
  }
  @keyframes move-26 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-15vw, -39vh) scale(0.27);
      -webkit-transform: translate(-15vw, -39vh) scale(0.27);
      transform: translate(-15vw, -39vh) scale(0.27);
    }
  }
  @keyframes move-27 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-11vw, -16vh) scale(1.5);
      -webkit-transform: translate(-11vw, -16vh) scale(1.5);
      transform: translate(-11vw, -16vh) scale(1.5);
    }
  }
  @keyframes move-28 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(13vw, -50vh) scale(1.95);
      -webkit-transform: translate(13vw, -50vh) scale(1.95);
      transform: translate(13vw, -50vh) scale(1.95);
    }
  }
  @keyframes move-29 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(2vw, -51vh) scale(1.6);
      -webkit-transform: translate(2vw, -51vh) scale(1.6);
      transform: translate(2vw, -51vh) scale(1.6);
    }
  }
  @keyframes move-30 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(6vw, -11vh) scale(0.5);
      -webkit-transform: translate(6vw, -11vh) scale(0.5);
      transform: translate(6vw, -11vh) scale(0.5);
    }
  }
  @keyframes move-31 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(0vw, 39vh) scale(0.82);
      -webkit-transform: translate(0vw, 39vh) scale(0.82);
      transform: translate(0vw, 39vh) scale(0.82);
    }
  }
  @keyframes move-32 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-5vw, 49vh) scale(0.36);
      -webkit-transform: translate(-5vw, 49vh) scale(0.36);
      transform: translate(-5vw, 49vh) scale(0.36);
    }
  }
  @keyframes move-33 {
    100% {
      @include bubbleSize;
      visibility: visible;
      -moz-transform: translate(-20vw, 1vh) scale(0.5);
      -webkit-transform: translate(-20vw, 1vh) scale(0.5);
      transform: translate(-20vw, 1vh) scale(0.5);
    }
  }
  @keyframes move-34 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(15vw, 47vh) scale(2.5);
      -webkit-transform: translate(15vw, 47vh) scale(2.5);
      transform: translate(15vw, 47vh) scale(2.5);
    }
  }
  @keyframes move-35 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-12vw, -44vh) scale(2.8);
      -webkit-transform: translate(-12vw, -44vh) scale(2.8);
      transform: translate(-12vw, -44vh) scale(2.8);
    }
  }
  @keyframes move-36 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(3vw, 54vh) scale(0.91);
      -webkit-transform: translate(3vw, 54vh) scale(0.91);
      transform: translate(3vw, 54vh) scale(0.91);
    }
  }
  @keyframes move-37 {
    100% {
      @include bubbleSize;
      visibility: visible;
      -moz-transform: translate(-10vw, 40vh) scale(0.14);
      -webkit-transform: translate(-10vw, 40vh) scale(0.14);
      transform: translate(-10vw, 40vh) scale(0.14);
    }
  }
  @keyframes move-38 {
    100% {
      @include bubbleSize;
      visibility: visible;
      -moz-transform: translate(25vw, 24vh) scale(0.1);
      -webkit-transform: translate(25vw, 24vh) scale(0.1);
      transform: translate(25vw, 24vh) scale(0.1);
    }
  }
  @keyframes move-39 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-14vw, 50vh) scale(0.22);
      -webkit-transform: translate(-14vw, 50vh) scale(0.22);
      transform: translate(-14vw, 50vh) scale(0.22);
    }
  }
  @keyframes move-40 {
    100% {
      @include bubbleSize;
      visibility: visible;
      -moz-transform: translate(7vw, 32vh) scale(0.4);
      -webkit-transform: translate(7vw, 32vh) scale(0.4);
      transform: translate(7vw, 32vh) scale(0.4);
    }
  }
  @keyframes move-41 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-10vw, 59vh) scale(1.81);
      -webkit-transform: translate(-10vw, 59vh) scale(1.81);
      transform: translate(-10vw, 59vh) scale(1.81);
    }
  }
  @keyframes move-42 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(17vw, 41vh) scale(0.84);
      -webkit-transform: translate(17vw, 41vh) scale(0.84);
      transform: translate(17vw, 41vh) scale(0.84);
    }
  }
  @keyframes move-43 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-18vw, -28vh) scale(0.28);
      -webkit-transform: translate(-18vw, -28vh) scale(0.28);
      transform: translate(-18vw, -28vh) scale(0.28);
    }
  }
  @keyframes move-44 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(5vw, 53vh) scale(0.83);
      -webkit-transform: translate(5vw, 53vh) scale(0.83);
      transform: translate(5vw, 53vh) scale(0.83);
    }
  }
  @keyframes move-45 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(12vw, 39vh) scale(0.4);
      -webkit-transform: translate(12vw, 39vh) scale(0.4);
      transform: translate(12vw, 39vh) scale(0.4);
    }
  }
  @keyframes move-46 {
    100% {
      @include bubbleSize;
      visibility: hidden;
      -moz-transform: translate(21vw, 37vh) scale(0.39);
      -webkit-transform: translate(21vw, 37vh) scale(0.39);
      transform: translate(21vw, 37vh) scale(0.39);
    }
  }
  @keyframes move-47 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(13vw, 49vh) scale(0.2);
      -webkit-transform: translate(13vw, 49vh) scale(0.2);
      transform: translate(13vw, 49vh) scale(0.2);
    }
  }
  @keyframes move-48 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-6vw, 20vh) scale(0.17);
      -webkit-transform: translate(-6vw, 20vh) scale(0.17);
      transform: translate(-6vw, 20vh) scale(0.17);
    }
  }
  @keyframes move-49 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(21vw, 56vh) scale(0.9);
      -webkit-transform: translate(21vw, 56vh) scale(0.9);
      transform: translate(21vw, 56vh) scale(0.9);
    }
  }
  @keyframes move-50 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(12vw, 4vh) scale(0.32);
      -webkit-transform: translate(12vw, 4vh) scale(0.32);
      transform: translate(12vw, 4vh) scale(0.32);
    }
  }
  @keyframes move-51 {
    100% {
      @include bubbleSize;
      visibility: hidden;
      -moz-transform: translate(36vw, -24vh) scale(0.41);
      -webkit-transform: translate(36vw, -24vh) scale(0.41);
      transform: translate(36vw, -24vh) scale(0.41);
    }
  }
  @keyframes move-52 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-18vw, -51vh) scale(2);
      -webkit-transform: translate(-18vw, -51vh) scale(2);
      transform: translate(-18vw, -51vh) scale(2);
    }
  }
  @keyframes move-53 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-9vw, -52vh) scale(0.3);
      -webkit-transform: translate(-9vw, -52vh) scale(0.3);
      transform: translate(-9vw, -52vh) scale(0.3);
    }
  }
  @keyframes move-54 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-17vw, 38vh) scale(0.9);
      -webkit-transform: translate(-17vw, 38vh) scale(0.9);
      transform: translate(-17vw, 38vh) scale(0.9);
    }
  }
  @keyframes move-55 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-1vw, -23vh) scale(0.13);
      -webkit-transform: translate(-1vw, -23vh) scale(0.13);
      transform: translate(-1vw, -23vh) scale(0.13);
    }
  }
  @keyframes move-56 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-19vw, 61vh) scale(1.12);
      -webkit-transform: translate(-19vw, 61vh) scale(1.12);
      transform: translate(-19vw, 61vh) scale(1.12);
    }
  }
  @keyframes move-57 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-21vw, 49vh) scale(1.8);
      -webkit-transform: translate(-21vw, 49vh) scale(1.8);
      transform: translate(-21vw, 49vh) scale(1.8);
    }
  }
  @keyframes move-58 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-11vw, 42vh) scale(0.65);
      -webkit-transform: translate(-11vw, 42vh) scale(0.65);
      transform: translate(-11vw, 42vh) scale(0.65);
    }
  }
  @keyframes move-59 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-22vw, 23vh) scale(1.9);
      -webkit-transform: translate(-22vw, 23vh) scale(1.9);
      transform: translate(-22vw, 23vh) scale(1.9);
    }
  }
  @keyframes move-60 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(0vw, 62vh) scale(0.5);
      -webkit-transform: translate(0vw, 62vh) scale(0.5);
      transform: translate(0vw, 62vh) scale(0.5);
    }
  }
  @keyframes move-61 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(7vw, -34vh) scale(0.22);
      -webkit-transform: translate(7vw, -34vh) scale(0.22);
      transform: translate(7vw, -34vh) scale(0.22);
    }
  }
  @keyframes move-62 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(16vw, 66vh) scale(1.59);
      -webkit-transform: translate(16vw, 66vh) scale(1.59);
      transform: translate(16vw, 66vh) scale(1.59);
    }
  }
  @keyframes move-63 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-20vw, 45vh) scale(0.5);
      -webkit-transform: translate(-20vw, 45vh) scale(0.5);
      transform: translate(-20vw, 45vh) scale(0.5);
    }
  }
  @keyframes move-64 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(4vw, 19vh) scale(1.15);
      -webkit-transform: translate(4vw, 19vh) scale(1.15);
      transform: translate(4vw, 19vh) scale(1.15);
    }
  }
  @keyframes move-65 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-4vw, -33vh) scale(1.7);
      -webkit-transform: translate(-4vw, -33vh) scale(1.7);
      transform: translate(-4vw, -33vh) scale(1.7);
    }
  }
  @keyframes move-66 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(3vw, -24vh) scale(0.88);
      -webkit-transform: translate(3vw, -24vh) scale(0.88);
      transform: translate(3vw, -24vh) scale(0.88);
    }
  }
  @keyframes move-67 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-22vw, 52vh) scale(0.14);
      -webkit-transform: translate(-22vw, 52vh) scale(0.14);
      transform: translate(-22vw, 52vh) scale(0.14);
    }
  }
  @keyframes move-68 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(5vw, -3vh) scale(0.3);
      -webkit-transform: translate(5vw, -3vh) scale(0.3);
      transform: translate(5vw, 3vh) scale(0.3);
    }
  }
  @keyframes move-69 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-8vw, 6vh) scale(1.2);
      -webkit-transform: translate(-8vw, 6vh) scale(1.2);
      transform: translate(-8vw, 6vh) scale(1.2);
    }
  }
  @keyframes move-70 {
    100% {
      @include bubbleSize;
      visibility: hidden;
      -moz-transform: translate(29vw, 32vh) scale(1.1);
      -webkit-transform: translate(27vw, 32vh) scale(1.1);
      transform: translate(27vw, 32vh) scale(1.1);
    }
  }
  @keyframes move-71 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(2vw, -43vh) scale(0.75);
      -webkit-transform: translate(2vw, -43vh) scale(0.75);
      transform: translate(2vw, -43vh) scale(0.75);
    }
  }
  @keyframes move-72 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(18vw, -6vh) scale(0.79);
      -webkit-transform: translate(18vw, -6vh) scale(0.79);
      transform: translate(18vw, -6vh) scale(0.79);
    }
  }
  @keyframes move-73 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(8vw, -1vh) scale(1.28);
      -webkit-transform: translate(8vw, -1vh) scale(1.28);
      transform: translate(8vw, -1vh) scale(1.28);
    }
  }
  @keyframes move-74 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-14vw, 67vh) scale(1.42);
      -webkit-transform: translate(-14vw, 67vh) scale(1.42);
      transform: translate(-14vw, 67vh) scale(1.42);
    }
  }
  @keyframes move-75 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(17vw, -41vh) scale(0.49);
      -webkit-transform: translate(17vw, -41vh) scale(0.49);
      transform: translate(17vw, -41vh) scale(0.49);
    }
  }
  @keyframes move-76 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-22vw, -6vh) scale(0.39);
      -webkit-transform: translate(-22vw, -6vh) scale(0.39);
      transform: translate(-22vw, -6vh) scale(0.39);
    }
  }
  @keyframes move-77 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(10vw, 3vh) scale(0.2);
      -webkit-transform: translate(10vw, 3vh) scale(0.2);
      transform: translate(10vw, 3vh) scale(0.2);
    }
  }
  @keyframes move-78 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-8vw, 13vh) scale(0.72);
      -webkit-transform: translate(-8vw, 13vh) scale(0.72);
      transform: translate(-8vw, 13vh) scale(0.72);
    }
  }
  @keyframes move-79 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(15vw, 31vh) scale(0.25);
      -webkit-transform: translate(15vw, 31vh) scale(0.25);
      transform: translate(15vw, 31vh) scale(0.25);
    }
  }
  @keyframes move-80 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-2vw, -17vh) scale(0.32);
      -webkit-transform: translate(-2vw, -17vh) scale(0.32);
      transform: translate(-2vw, -17vh) scale(0.32);
    }
  }
  @keyframes move-81 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-17vw, -21vh) scale(2.26);
      -webkit-transform: translate(-17vw, -21vh) scale(2.26);
      transform: translate(-17vw, -21vh) scale(2.26);
    }
  }
  @keyframes move-82 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(17vw, -45vh) scale(0.95);
      -webkit-transform: translate(17vw, -45vh) scale(0.95);
      transform: translate(17vw, -45vh) scale(0.95);
    }
  }
  @keyframes move-83 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-21vw, -51vh) scale(1.2);
      -webkit-transform: translate(-21vw, -51vh) scale(1.2);
      transform: translate(-21vw, -51vh) scale(1.2);
    }
  }
  @keyframes move-84 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-15vw, -14vh) scale(0.8);
      -webkit-transform: translate(-15vw, -14vh) scale(0.8);
      transform: translate(-15vw, -14vh) scale(0.8);
    }
  }
  @keyframes move-85 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-6vw, -8vh) scale(0.13);
      -webkit-transform: translate(-6vw, -8vh) scale(0.13);
      transform: translate(-6vw, -8vh) scale(0.13);
    }
  }
  @keyframes move-86 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(0vw, 19vh) scale(1.65);
      -webkit-transform: translate(0vw, 19vh) scale(1.65);
      transform: translate(0vw, 19vh) scale(1.65);
    }
  }
  @keyframes move-87 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(13vw, 17vh) scale(1.35);
      -webkit-transform: translate(13vw, 17vh) scale(1.35);
      transform: translate(13vw, 17vh) scale(1.35);
    }
  }
  @keyframes move-88 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-1vw, 34vh) scale(0.35);
      -webkit-transform: translate(-1vw, 34vh) scale(0.35);
      transform: translate(-1vw, 34vh) scale(0.35);
    }
  }
  @keyframes move-89 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(-8vw, -2vh) scale(0.59);
      -webkit-transform: translate(-8vw, -2vh) scale(0.59);
      transform: translate(-8vw, -2vh) scale(0.59);
    }
  }
  @keyframes move-90 {
    100% {
      @include bubbleSize;
      -moz-transform: translate(6vw, -24vh) scale(0.5);
      -webkit-transform: translate(6vw, -24vh) scale(0.5);
      transform: translate(6vw, -24vh) scale(0.5);
    }
  }
}

@keyframes fade-in-up {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 0;
  }
  30% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0);
  }
  57% {
    transform: translateY(-8px);
  }
  64% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
    visibility: visible;
  }
}
