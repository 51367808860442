.tile {
  &.large {
    .task {
      &.calltoaction {
        .form-group {
          &.submit {
            text-align: left;
            @media screen and (max-width: 1023px) {
              text-align: center;
            }
            @media screen and (min-width: 1440px) {
              padding: 0 20px;
            }
          }
        }
      }
    }
  }
}
