//*******************************************************************************************/
//*************** pages/main.scss - the starting file for global-defaults *******************/
//*******************************************************************************************/

.take-quiz {
  .secondary-btn {
    background-color: $white;
    border: none;
  }
}
