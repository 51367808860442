$overlay-color: white !default;
$overlay-background-color: rgba(0, 0, 0, 0.85) !default;

$takeover-padding: 10px 0px 0px 0px;

$takeover-transition-duration: 600ms !default;
$takeover-transition-easing: cubic-bezier(0.25, 0.25, 0.75, 0.75) !default;
$takeover-transition-content-delay: ($takeover-transition-duration / 2) !default;
$takeover-transition-content-duration: ($takeover-transition-duration / 2) !default;

.takeover {
  .takeover-overlay {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000;
    color: $overlay-color;
    background-color: $overlay-background-color;
    text-align: center;
    padding: $takeover-padding;
    overflow-y: auto;
    .content {
      background-color: rgba(0, 0, 0, 0.85);
    }
    &.takeover-animate-enter {
      opacity: 0;
      transition: $takeover-transition-content-duration $takeover-transition-easing all;

      .content {
        transform: translateY(800px) scale(0);
        transition-delay: $takeover-transition-content-delay;
        transition: $takeover-transition-content-duration $takeover-transition-easing transform;
      }
    }

    &.takeover-animate-enter.takeover-animate-enter-active {
      opacity: 1;

      .content {
        transform: translateY(0%) scale(1);
      }
    }

    &.takeover-animate-exit {
      opacity: 1;
      transition: $takeover-transition-content-duration $takeover-transition-easing all;

      .content {
        transform: translateY(0%) scale(1);
        transition: $takeover-transition-content-duration $takeover-transition-easing transform;
      }
    }

    &.takeover-animate-exit.takeover-animate-exit-active {
      opacity: 0;

      .content {
        transform: translateY(800px) scale(0);
      }
    }
  }
}
